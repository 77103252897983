exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  console.log(location)
  if (location.state?.fromNavigation) {
    return false;
  }

  return true;
};