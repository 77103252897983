exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-english-js": () => import("./../../../src/pages/english.js" /* webpackChunkName: "component---src-pages-english-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-place-js": () => import("./../../../src/pages/place.js" /* webpackChunkName: "component---src-pages-place-js" */),
  "component---src-pages-podcast-js": () => import("./../../../src/pages/podcast.js" /* webpackChunkName: "component---src-pages-podcast-js" */),
  "component---src-pages-prices-js": () => import("./../../../src/pages/prices.js" /* webpackChunkName: "component---src-pages-prices-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-program-js": () => import("./../../../src/pages/program.js" /* webpackChunkName: "component---src-pages-program-js" */),
  "component---src-pages-qna-js": () => import("./../../../src/pages/qna.js" /* webpackChunkName: "component---src-pages-qna-js" */),
  "component---src-pages-speakers-js": () => import("./../../../src/pages/speakers.js" /* webpackChunkName: "component---src-pages-speakers-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */)
}

